/* Main Font Type */ 

@font-face {
  font-family: 'TheSans-XLight';
  src: url('../fonts/TheSans/TheSans-2-XLight.otf') format('opentype');
       
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TheSans-Light';
  src: url('../fonts/TheSans/TheSans-3-Light.otf') format('opentype');
       
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TheSans-SemiLight';
  src: url('../fonts/TheSans/TheSans-4-SemiLight.otf') format('opentype');
       
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TheSans-Regular';
  src: url('../fonts/TheSans/TheSans-5-Regular.otf') format('opentype');
       
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TheSans-SemiBold';
  src: url('../fonts/TheSans/TheSans-6-SemiBold.otf') format('opentype');
       
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TheSans-Bold';
  src: url('../fonts/TheSans/TheSans-7-Bold.otf') format('opentype');
       
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TheSans-XBold';
  src: url('../fonts/TheSans/TheSans-8-XBold.otf') format('opentype');
       
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TheSans-Black';
  src: url('../fonts/TheSans/TheSans-9-Black.otf') format('opentype');
       
  font-weight: normal;
  font-style: normal;
}


body {
  font-family: 'TheSans-Regular',sans-serif !important;
}

/* BASIS START */
#page {
  font-family: 'TheSans-Regular',sans-serif;
  font-size: 18px !important;
  background-color: #{'rgb(var(--main-bg-color))'};
}

.fa-paragraph:before {
  content: '§';
  font-size: 1.3em;
  font-weight: bold;
}

#page img {
  width: 100%;
}

#page p {
  font-weight: 300;
}

#page a {
  color: #000;
  font-size: 0.85em;
  font-weight: 600;
  text-transform: uppercase;
}

#page a[href^='tel:'] {
  font-size: 1em;
  font-weight: 300;
}

#page i {
  font-size: 0.9em;
}

#page button {
  position: relative;
}

#page h1 {
  font-size: 2.6em;
  font-weight: 900;
  line-height: 2.6em;
  text-transform: uppercase;
  margin-top: 0px;
  margin-bottom: 20px;
}

#page h2 {
  font-size: 1.3em;
  font-weight: 900;
  line-height: 1.3em;
  text-transform: uppercase;
  margin-top: 0px;
  margin-bottom: 20px;
}

#page h3 {
  font-size: 1.1em;
  font-weight: 900;
  line-height: 1.1em;
  text-transform: uppercase;
  margin-top: 0px;
  margin-bottom: 20px;
}

#page h4 {
  font-size: 1em;
  font-weight: 600;
  line-height: 1em;
  text-transform: uppercase;
  margin-top: 0px;
  margin-bottom: 20px;
}

#page b {
  font-weight: 600;
}

/* BASIS ENDE */

/* SEITENSPEZIFISCHE FARBEN START */
#page .bg-blue, #page .bg-concept {
  background-color: rgb(153, 235, 255) !important;
  color: #242424 !important;
}

#page .text-blue, #page .text-concept {
  color: rgb(134, 214, 234) !important;
}

#page .link-blue:hover, #page .link-concept:hover {
  color: rgb(134, 214, 234) !important;
}

#page .border-blue, #page .border-concept {
  border-color: rgb(153, 235, 255) !important;
}

#page .btn-blue:hover, #page .btn-concept:hover {
  background-color: rgb(153, 235, 255) !important;
  color: #242424 !important;
}

#page .bg-red, #page .bg-work {
  background-color: rgb(213, 65, 103) !important;
  color: #fff !important;
}

#page .text-red, #page .text-work {
  color: rgb(213, 65, 103) !important;
}

#page .link-red:hover, #page .link-work:hover {
  color: rgb(213, 65, 103) !important;
}

#page .border-red, #page .border-work {
  border-color: rgb(213, 65, 103) !important;
}

#page .btn-red:hover, #page .btn-work:hover {
  background-color: rgb(213, 65, 103) !important;
  color: #fff !important;
}

#page .bg-green, #page .bg-teams {
  background-color: rgb(84, 185, 67) !important;
  color: #fff !important;
}

#page .text-green, #page .text-teams {
  color: rgb(84, 185, 67) !important;
}

#page .link-green:hover, #page .link-teams:hover {
  color: rgb(84, 185, 67) !important;
}

#page .border-green, #page .border-teams {
  border-color: rgb(84, 185, 67) !important;
}

#page .btn-green:hover, #page .btn-teams:hover {
  background-color: rgb(84, 185, 67) !important;
  color: #fff !important;
}

#page .bg-yellow, #page .bg-jobs {
  background-color: rgb(254, 210, 1) !important;
  color: #242424 !important;
}

#page .text-yellow, #page .text-jobs {
  color: rgb(254, 210, 1) !important;
}

#page .link-yellow:hover, #page .link-jobs:hover {
  color: rgb(254, 210, 1) !important;
}

#page .border-yellow, #page .border-jobs {
  border-color: rgb(254, 210, 1) !important;
}

#page .btn-yellow:hover, #page .btn-jobs:hover {
  background-color: rgb(254, 210, 1) !important;
  color: #242424 !important;
}

#page .link-brown:hover, #page .link-contact:hover, #page .link-service:hover {
  color: #d1643d !important;
}

#page .text-brown, #page .text-contact, #page .text-service {
  color: #d1643d !important;
}

#page .bg-brown, #page .bg-contact, #page .bg-service {
  background-color: rgb(235, 163, 139) !important;
  color: #242424 !important;
}

#page .border-brown, #page .border-contact, #page .border-service {
  border-color: #d1643d !important;
}

#page .btn-brown:hover, #page .btn-contact:hover, #page .btn-service:hover {
  background-color: #d1643d !important;
  color: #242424 !important;
}

#page .grau {
  background-color: rgb(238, 238, 238);
}

/* SEITENSPEZIFISCHE FARBEN ENDE */

/* WIEDERHOLENDE ELEMENTE START */
#page .social-bar {
  margin: 0px auto;
  text-align: center;
}

/* WIEDERHOLENDE ELEMENTE ENDE */

/* HEADER NAVBAR START */
#page header {
  padding-top: 51px;
}

#page header .navbar-inverse {
  background-color: rgb(36, 36, 36);
}

#page header .navbar-brand {
  color: #fff !important;
  text-transform: none;
}

#page header .nav>li>a {
  padding-left: 8px;
  padding-right: 8px;
  margin-bottom: -1px;
  color: #fff;
}

#page header .navbar-inverse .navbar-nav>li>a {
  color: #fff;
}

#page header .navbar ul li {
  font-size: 0.85em;
  font-weight: 600;
  line-height: 20px;
  color: #ffffff;
  text-transform: uppercase;
}

#page header .navbar ul li.active a {
  background-color: transparent !important;
  border-bottom: 2px solid;
}

#page header .navbar::after {
  clear: both;
}

#page header .leiste.titel {
  height: 167px;
}

#page header .page-title {
  margin-top: 100px;
  border-bottom: 2px solid;
  display: inline-block;
}

#page header .page-title h1 {
  line-height: 47px;
  margin: 0px 0px 18px 0px;
  text-transform: uppercase;
  font-size: 2.6em;
  font-weight: 900;
}

#page header .headerbg {
  background-repeat: no-repeat;
  background-size: cover;

}

.greyscale {
  filter: grayscale(100%);
  /* Standard */
  -webkit-filter: grayscale(100%);
  /* Webkit */
  filter: url(/elements/grayscale.svg#desaturate);
  /* Firefox 4-34 */
  filter: $darkgrey;
  /* IE6-9 */
  -webkit-filter: grayscale(1);
  /* Old WebKit */
  -webkit-transition: 1000ms;
  transition: 1000ms;
  &:hover {
      filter: grayscale(0%);
      filter: none;
      -webkit-filter: grayscale(0);
      /* Old WebKit */
  }
}



@media(max-width: 768px) {
  .navbar-collapse.pull-right {
    float: none !important;
  }

  .nav a {
    font-size: 1.15em;
    font-weight: 600;
    text-transform: uppercase;
  }
}

/* HEADER NAVBAR ENDE */

/* BTN STYLES START */
#page .btn {
  margin: 0;
  padding: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  border-radius: 0;
}

#page .btn:after {

  position: absolute;
  z-index: -1;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

#page .btn-1 {
  color: #fff;
}

#page .dark {
  background-color: rgb(36, 36, 36);
  color: #fff;
}

#page .bright {
  background-color: #fff;
  color: rgb(36, 36, 36);
}

#page .btn.hoehenziel.weitenziel {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

#page #start-button {
  width: 100%;
}

/* BTN STYLES ENDE */

/* CONTENT START */
#page #content-main,
#page #content-sub {
  font-family: 'TheSans-Regular',sans-serif;
  font-size: 100%;
  font-weight: 300;
  margin-top: 40px;
  margin-bottom: 40px;
  word-wrap: break-word;
}

#page #content-sub {
  margin-bottom: 37px;
}

/* CONTENT ENDE */

/* META START */
#page #meta-two {
  margin-top: 3px;
  margin-bottom: 0px;
}

#page #meta-two .service-menu {
  text-align: right;
}

/* META ENDE */

/* TOOLTIP START */
#page *,
#page *:before,
#page *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

#page a:hover {
  text-decoration: none;
}

#page .tooltip.in {
  opacity: 1;
}

#page .tooltip-inner {
  background-color: #fff;
  color: #242424;
  padding: 15px 30px;
  line-height: 0.85em;
  font-size: 1.15em;
  font-weight: 400;
  text-transform: uppercase;
  font-family: 'TheSans-Regular',sans-serif;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -webkit-box-shadow: 0 2px 0 0 rgba(18, 18, 18, 0.18);
  box-shadow: 0 2px 0 0 rgba(18, 18, 18, 0.18);
  max-width: 500px;
  white-space: nowrap;
}

/* Triangle hack to make tooltip look like a speech bubble */
#page .right .tooltip-arrow::after {
  content: "";
  top: 2px;
  left: -1px;
  height: 10px;
  width: 6px;
  border-color: transparent;
  position: absolute;
  z-index: -1;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: rgba(18, 18, 18, 0.18);
  border-style: solid;
}

#page .left .tooltip-arrow::after {
  content: "";
  top: 2px;
  right: -1px;
  height: 10px;
  width: 6px;
  border-color: transparent;
  position: absolute;
  z-index: -1;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: rgba(18, 18, 18, 0.18);
  border-style: solid;
}

#page .tooltip-arrow-right {
  border-width: 5px 5px 0 5px;
  border-color: #fff !important;
}

#page .tooltip-arrow-right::after {
  border-width: 5px 5px 0 5px;
  border-color: #000 !important;
  margin-top: 2px;
}

#page .tooltip.right .tooltip-arrow {
  border-right-color: #fff;
}

#page .tooltip.left .tooltip-arrow {
  border-left-color: #fff;
}

/* TOOLTIP ENDE */

/* TEAM IMAGE START */
#page div[id*='-team-image'] {
  position: relative;
}

#page div[id*='-team-image']>img {
  display: block;
}

#page div[id*='-team-image'] .team-image-inner {
  position: absolute;
  width: 100%;
  min-height: 250px;
  height: 100%;
  top: 0px;
}

#page div[id*='-team-image'] div[id*='-team-image-item'] {
  position: absolute;
  top: 0px;
  width: 7%;
  -webkit-border-radius: 40px;
  border-radius: 40px;
  border: 5px dashed transparent;
}

#page div[id*='-team-image'] div[id*='-team-image-item']:hover {
  border-color: rgba(84, 185, 67, 1.0) !important;
  cursor: pointer;
}

#page div[id*='-team-image'] div[id*='-team-image-item'] .tooltip .tooltip-inner {
  background-color: rgb(84, 185, 67) !important;
  color: #FFFFFF;
  padding-left: 15px;
  padding-right: 15px;
  text-align: left;
  line-height: 1.25em;
  padding-top: 10px;
  padding-bottom: 10px;
}

#page div[id*='-team-image'] div[id*='-team-image-item'] .tooltip.left .tooltip-arrow {
  border-left-color: rgb(84, 185, 67) !important;
}

#page div[id*='-team-image'] div[id*='-team-image-item'] .tooltip.right .tooltip-arrow {
  border-right-color: rgb(84, 185, 67) !important;
}

#page div[id*='-team-image'] div[id*='-team-image-item'] .tooltip .tooltip-inner a {
  color: #FFFFFF;
  font-size: 1.15em;
  padding-left: 10px;
}

/* TEAM IMAGE ENDE */

/* WORK ÜBERSICHT START */
#page .karte a {
  color: #fff;
}

#page .project-link {
  text-align: right;
}

#page .back-position {
  margin: 30px;
}


#page .work-item a,
#page .work-item a:focus,
#page .work-item a:visited,
#page .work-item a:active {
  text-decoration: none;
  outline: none;
}


#page .work-item a .back-position,
#page .work-item a:focus .back-position,
#page .work-item a:visited .back-position,
#page .work-item a:active .back-position {
  text-decoration: none;
}


#page .work-item a .back-position p,
#page .work-item a:visited .back-position p,
#page .work-item a:active .back-position p {
  text-transform: none;
}

#page .work-item a .back-position p.project-link {
  font-size: 0.85em;
  font-weight: 600;
  text-transform: uppercase;
}


#page .work-row {
  margin-bottom: -30px;
}

#page .work-item {
  position: relative;
  perspective: 800px;
  -webkit-perspective: 800px;
  -ms-perspective: 800px;
  cursor: pointer;
  margin-bottom: 30px;
}

#page .karte {
  width: 100%;
  height: 100%;
  position: absolute;
}

#page .karte .front,
#page .karte .back {
  display: block;
  position: absolute;
  width: 100%;
  height: 101%;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -ms-transition: transform 0.5s;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

#page .karte .front {
  border: 1px solid rgb(238, 238, 238);
}

#page .karte .back {
  transform: rotateY(-180deg);
  -webkit-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
}

#page .karte .front.flipped {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
}

#page .karte .back.flipped {
  transform: rotateY(0deg);
  -webkit-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
}

/* WORK ÜBERSICHT ENDE */

/* GALLERY START */
#page .gallery .gallery-slider {
  position: relative;
  overflow: auto;
  margin-bottom: 30px;
}

#page .gallery .gallery-slider .gallery-loading {
  min-height: 250px;
  background: url('../img/loading.gif') center center no-repeat;
}

#page .gallery .gallery-slider ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
  clear: both;
}

#page .gallery .gallery-slider ul li {
  display: none;
  float: left;
}

#page .gallery .gallery-slider .arrow {
  display: none;
  position: absolute;
  cursor: pointer;
  top: 45%;
  text-align: center;
  opacity: 0.6;
}

#page .gallery .gallery-slider .arrow.prev {
  left: 0px;
}

#page .gallery .gallery-slider .arrow.next {
  right: 0px;
}

#page .gallery .gallery-slider .arrow:hover {
  opacity: 1.0;
}

#page .gallery .gallery-nav .gallery-nav-item {
  display: inline-block;
  margin-right: 3px;
  width: 20%;
  opacity: 0.4;
  cursor: pointer;
}

#page .gallery .gallery-nav .gallery-nav-item.active {
  opacity: 1.0;
}

#page .gallery .gallery-nav .gallery-nav-item img {
  display: block;
  border: 0px;
}

/* GALLERY ENDE */

/* KONTAKT START */
#page .ajax-form,
#page {
  position: relative;
}

#page .ajax-form label {
  display: none;
}

#page .ajax-form .actions ul {
  padding: 0px;
  margin: 0px;
  list-style-type: none;
}

#page .ajax-form .form-control {
  border-radius: 0px;
  background: #f4f4f4;
  border: 0px;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-size: 17px;
  color: #000000;
  padding: 15px;
  height: 47px;
}

#page .ajax-form select {
  -webkit-appearance: none;
}

#page .ajax-form textarea.form-control {
  height: 274px;
  resize: none;
}

#page .ajax-form .form-control::-webkit-input-placeholder {
  color: #000000;
  text-transform: uppercase;
}

#page .ajax-form .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #000000;
  text-transform: uppercase;
}

#page .ajax-form .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #000000;
  text-transform: uppercase;
}

#page .ajax-form .form-control:-ms-input-placeholder {
  color: #000000;
  text-transform: uppercase;
}

#page #contact-form .actions {
  position: relative;
  bottom: 0px;
  right: 0px;
}

#page .ajax-form #contact-form-section-three label {
  display: inline;
}

#page .ajax-form #contact-form-section-three label[for=contact-form-dsgvo-checkbox01] {
  margin-left: 10px;
}


@media (max-width: 767px) {

  #page .ajax-form .actions {
    position: static;
    bottom: auto;
    right: auto;
  }

}

#page .ajax-form .actions ul .submit {
  text-align: right;
}

#page .ajax-form .actions ul .submit .btn {
  padding-top: 15px;
  padding-bottom: 15px;
  background: transparent;
  color: rgb(235, 163, 139) !important;
  text-transform: uppercase;
  border: 0px;
  font-size: 15px;
}

#page .ajax-form .help-inline {
  padding-left: 15px;
  color: rgb(235, 163, 139);
  font-weight: 500;
}

#page .ajax-form .form-group {
  margin-bottom: 10px;
}

/* KONTAKT ENDE */

/* LINK BOX START */
#page div[id*='-link-box'] {
  padding-bottom: 2px;
}

#page div[id*='-link-box'] a {
  display: block;
  background: #f4f4f4;
  font-size: 15px;
  line-height: 15px;
  color: #000000;
  text-transform: uppercase;
  padding: 20px;
}

#page div[id*='-link-box'] a .fa {
  margin-right: 10px;
}

#page div[id*='-link-box'] a:hover span {
  text-decoration: underline;
}

/* LINK BOX ENDE */

/* START PAGE START */
#page #start-page .feld {
  background-color: #C03;
  margin-left: -20px;
  background-size: cover;
}

#page #start-page .feld:hover {
  background-size: 110% auto;
  filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  -moz-filter: blur(2px);
  -webkit-filter: blur(2px);
}

#page #start-page .concept {
  background-image: url('../img/concept_image.jpg');
}

#page #start-page .kontakt {
  background-image: url('../img/kontakt_image.jpg');
}

#page #start-page .team {
  background-image: url('../img/team_image.jpg');

}

#page #start-page .work {
  background-image: url('../img/work_image.jpg');

}

#page #start-page .jobs {
  background-image: url('../img/jobs_image.jpg');
  background-color: #fff;
  z-index: 1000;
  position: relative;
}

#page #start-page .service {
  background-image: url('../img/service_image.jpg');
  background-color: #fff;
  z-index: 1000;
  position: relative;
}

#page #start-page .stoerer {
  position: absolute;
  z-index: 1000;
  margin-left: 67%;
  width: 18%;
  margin-top: -3%;

  -moz-transform: rotate(33deg);
  -webkit-transform: rotate(33deg);
  -o-transform: rotate(33deg);
  -ms-transform: rotate(33deg);
  transform: rotate(33deg);

  -moz-transition: all 0.6s ease-in-out;
  -webkit-transition: all 0.6s ease-in-out;
  -o-transition: all 0.6s ease-in-out;
  -ms-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
}

#page #start-page .klickflaeche_concept:hover .stoerer {
  -moz-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}

#page #start-page .bolle {
  position: absolute;
  z-index: 1000;
  margin-left: 72%;
  width: 9%;
  margin-top: 10%;
}

#page #start-page .woot {
  display: none;
  z-index: 10000;
}

#page #start-page .woot2 {
  font-weight: bold;
  color: #000;
  position: absolute;
  top: 46%;
  font-size: 22px;
  left: 52%;

  -moz-transform: rotate(-2deg);
  -webkit-transform: rotate(-2deg);
  -o-transform: rotate(-2deg);
  -ms-transform: rotate(-2deg);
  transform: rotate(-2deg);
}

#page #start-page .woot1 {
  font-weight: bold;
  color: #000;
  position: absolute;
  top: 6%;
  font-size: 25px;
  left: 51%;

  -moz-transform: rotate(-9deg);
  -webkit-transform: rotate(-9deg);
  -o-transform: rotate(-9deg);
  -ms-transform: rotate(-9deg);
  transform: rotate(-9deg);
}

#page #start-page .supertyp {
  position: absolute;
  z-index: 1000;
  margin-left: -90%;
  width: 14%;
  margin-top: 5%;
}

#page #start-page .first {
  margin-top: 45px;
}

#page #start-page .logo {
  margin-top: 70px;
}

#page #start-page .help {
  position: absolute;
  display: none;
  top: 6px;
  width: 36%;
  left: -80px;
  
}

#page #start-page .help {
  -moz-animation-duration: 0.25s;
  -webkit-animation-duration: 0.25s;
  -o-animation-duration: 0.25s;
  -ms-animation-duration: 0.25s;
  animation-duration: 0.25s;
}

/* TICKER START */
#page .ticker {
  position: relative;
  margin: 25px 0 0;
}

#page .ticker:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100px;
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMCIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 1)), color-stop(100%, rgba(255, 255, 255, 0)));
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  background: -o-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  background: -ms-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=1);
  z-index: 99;
}

#page .ticker:after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 100px;
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(100%, rgba(255, 255, 255, 1)));
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=1);
  z-index: 99;
}

#page .simply-scroll-container {
  position: relative;
}

#page .simply-scroll-clip {
  position: relative;
  overflow: hidden;
}

#page .simply-scroll-list {
  overflow: hidden;
  margin: 0;
  padding: 0;
  list-style: none;
}

#page .simply-scroll-list li {
  padding: 0;
  margin: 0;
  list-style: none;
}

#page .simply-scroll-list li img {
  border: none;
  display: block;
  height: 100%;
  width: auto;
}

#page .simply-scroll {
  width: 100%;
  height: 200px;
  margin-bottom: 1em;
}

#page .simply-scroll .simply-scroll-clip {
  width: 100%;
  height: 200px;
}

#page .simply-scroll .simply-scroll-list li {
  float: left;
  height: 200px;
}

/* TICKER END */


@media(min-width: 1199px) {
  #page #start-page .stoerer {
    float: left;
    margin-left: 480px;
    width: 115px;
    margin-top: -10px;
  }

  #page #start-page .help {
    position: absolute;
    top: 6px;
    width: 100px;
    left: -80px;
  }

  #page #start-page .bolle {
    float: left;
    margin-left: 453px;
    width: 55px;
    margin-top: 80px;
  }

  #page #start-page .woot2 {
    font-weight: bold;
    color: #000;
    position: absolute;
    top: 47px;
    font-size: 22px;
    left: 356px;

    -moz-transform: rotate(-2deg);
    -webkit-transform: rotate(-2deg);
    -o-transform: rotate(-2deg);
    -ms-transform: rotate(-2deg);
    transform: rotate(-2deg);
  }

  #page #start-page .woot1 {
    font-weight: bold;
    color: #000;
    position: absolute;
    top: 11px;
    font-size: 25px;
    left: 350px;

    -moz-transform: rotate(-9deg);
    -webkit-transform: rotate(-9deg);
    -o-transform: rotate(-9deg);
    -ms-transform: rotate(-9deg);
    transform: rotate(-9deg);
  }
}

@media(max-width: 1199px) {
  #page header .leiste.titel {
    height: 132px;
  }

  #page header .page-title {
    margin-top: 65px;
  }
}

@media(max-width: 991px) {
  #page #start-page .logo {
    margin-top: 62px;
  }

  #page #start-page .help {
    top: 20%;
    width: 23%;
    left: -20%;
  }

  #page header .leiste.titel {
    height: 117px;
  }

  #page header .page-title {
    margin-top: 50px;
  }

  #page header .page-title h1 {
    font-size: 2em;
  }
}

@media(min-width: 768px) {
  #page header .navbar-nav {
    float: right;
  }

  #page .flip:first-child {
    float: right;
  }
}

@media(max-width: 767px) {
  #page #start-page .logo {
    margin-top: 40px;
  }

  #page #start-page .first {
    margin-top: 45px;
  }

  #page #start-page .woot2 {
    font-size: 18px;
  }

  #page #start-page .help {
    top: 2%;
    width: 30%;
    left: -25%;
  }

  #page #start-page .woot1 {
    font-size: 22px;
  }

  #page #content-sub {
    margin-top: 0;
  }

  #page div[id*='-team-image'] {
    position: static;
    background-color: rgb(84, 185, 67);
    color: #FFFFFF;
    padding: 10px 15px;
    margin: 15px 0;
    text-align: left;
    line-height: 1.25em;
    font-size: 0.9em;
    font-weight: 400;
    text-transform: uppercase;
    font-family: 'TheSans-Regular',sans-serif;
    -webkit-box-shadow: 0 2px 0 0 rgba(18, 18, 18, 0.18);
    box-shadow: 0 2px 0 0 rgba(18, 18, 18, 0.18);
    max-width: 500px;
  }

  #page div[id*='-team-image-item'] a {
    color: #fff;
  }

  #page .facebook-box {
    margin-top: 30px;
  }

}

#page #start-page .visible {
  display: inherit;
}

/* START PAGE ENDE */