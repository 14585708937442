.neos-nodetypes-headline {
    &.color-dark {
        color: $darkgrey;
    }
    &.color-light {
        color: $lightgrey;
    }
    &.color-orange {
        color: $orange;
    }
    &.color-white {
        color: #fff;
    }
}