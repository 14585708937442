.article-preview-wrapper {
    position: relative;
    min-height: 300px;
    overflow: hidden;
    margin-bottom: 15px;
    border-radius: 2px;
  
    cursor: pointer;
  
    .image-container {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
  
      background-position: center center;
      background-size: cover;
  
      transform: scale(1);
      transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  
    &:hover {
      .image-container {
        transform: scale(1.2);
      }
    }
  
    .hover-overlay {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
  
      padding-top: 100px;
      text-align: center;
      color: white;
      font-weight: normal;
  
      // background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.65) 100%);
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
  
      transition: bottom 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  
      .title {
        padding: 10px;
        padding-bottom: 15px;
        font-size: 15pt;
        text-shadow: 1px 1px 5px $dark-orange;
      }
  
      .intro {
        padding: 10px;
        padding-top: 0;
  
        .more {
          display: inline-block;
          margin-left: 20px;
          font-style: italic;
        }
      }
    }
  
    .static-date-block {
      display: none;
  
      position: absolute;
      top: 7px;
      left: 7px;
      z-index: 999;
      color: white;
      text-shadow: 1px 1px 5px $dark-orange;
    }
  }
  