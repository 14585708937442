.section {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 10px 0;
  // color: white;

  .inner {
    position: relative;

    &.container-fluid {
      padding: 0;
    }
  }

  &.rootcolor-bg {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ff7d00+0,ff6700+100 */
    background-color: #{'rgba(var(--main-bg-color), 1)'};
    padding: 15px 0 25px;
  }

  &.color-dark {
    background-color: $darkgrey;
    padding: 15px 0 25px;
  }

  &.color-light {
    background-color: $lightgrey;
    padding: 15px 0 25px;
  }

  &.color-orange {
    background-color: $orange;
    padding: 15px 0 25px;
  }

  &.color-white {
    background-color: #fff;
    padding: 15px 0 25px;
  }

  // &.no-bg {
  //   color: $darkgrey;
  // }

  // &:not(.no-bg, .rootcolor-bg) {
  //   .inner>.neos-contentcollection {

  //     .neos-nodetypes-text,
  //     .neos-nodetypes-headline {
  //       text-shadow: 0 0 7px darken($darkgrey, 10%);
  //     }
  //   }
  // }
}

.parallax {
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}