.alert-box {
  --color: #cf4d03;

  display: flex;
  margin: 10px 0;
  width: 100%;

  &.color-info {
    --color: #0288d1;
  }

  &.color-success {
    --color: #0f7d15;
  }

  &.color-warning {
    --color: #cf4d03;
  }

  &.color-danger {
    --color: #ab0f0e;
  }

  &.stretched {
    margin: 25px 0;
  }

  .content-left {
    align-items: center;
    background-color: var(--color);
    box-sizing: content-box !important;
    color: #fff;
    display: flex;
    font-size: 1.2em;
    justify-content: space-around;
    padding: 5px;
    min-width: 35px;

    i.fa {
      font-size: 1.5em !important;
    }
  }

  .content-right {
    border-bottom: 5px solid var(--color);
    border-right: 5px solid var(--color);
    border-top: 5px solid var(--color);
    box-sizing: border-box;
    color: #000;
    min-height: 40px;
    padding: 3px;
    width: 100%;

    .title-wrapper {
      font-size: 1.05em;
      font-weight: 600;
    }

    .content-wrapper {
      font-size: 0.95em;
    }
  }

  &.filled {
    .content-right {
      background-color: var(--color);
      color: #fff;
    }
  }
}