.article-overview {
    .neos-widget-paginator {
      list-style: none;
      padding: 0;
      text-align: center;

      & > li {
        display: inline-block;
        padding: 10px 5px;
        line-height: 20px;
        color: $orange;
        font-weight: normal;

        &.active {
          font-weight: bold;
          text-decoration: underline;
        }

        &.next, &.previous {
          font-size: 18pt;
        }
      }
    }
  }

  .filter-buttons {
    text-align: right;
    margin-bottom: 20px;

    .filter-button {
      display: inline-block;
      padding: 10px 10px 7px;
      background: $orange;
      color: #000000;
      border: 1px solid #000000;
      font-weight: lighter;
      margin-right: 7px;
      text-transform: uppercase;

      .glyphicons::before {
        padding: 0;
      }

      &.active {
        background: #ffffff;
        color: #000000;
        border: 1px solid #ffffff;
      }
    }
  }

  .nothing-found-notice {
    text-align: center;
    font-size: 13pt;
    padding: 30px 15px;
  }
