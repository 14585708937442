.panel-group {
  color: darkgrey;
  border-color: lightgrey;
  .panel {
    border-radius: 0;
    color: inherit;
    background: white;
    border-color: inherit;
    a {
      text-decoration: none;

      .panel-title {
        font-weight: 600;
        text-transform: uppercase;
      }

      .panel-heading::after {
        // Cheated FontAwesome Icon: fa-chevron-down
        content: '\f078';
        display: inline-block;
        font-family: FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        right: 35px;
        position: absolute;
        margin-top: -18px;
        transform: rotate(0deg);
        transition: transform 0.2s ease-in-out;
      }
    }

    a[aria-expanded='true'] {
      .panel-heading::after {
        transform: rotate(180deg);
      }
    }

    .panel-collapse {
      border-top: 0.7px solid;
      border-color: inherit;
    }
  }
}
