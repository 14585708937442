.cc-grower {
  position: fixed;
  z-index: 99999;
  max-width: 100vw;

  // Prevent popup from exceeding the viewport
  max-height: 100vh !important;
  overflow: auto;
  .cc-message {
      max-height: 70vh;
      overflow: auto;
  }
  // END Prevent popup from exceeding the viewport
}

.cc-window.cc-banner {
  left: unset;
  right: unset;
}

.cc-revoke {
  //display: none;
  position: fixed;
  z-index: 999999;

  @media (hover: hover) {
    right: 5em !important;
    left: unset !important;
    background-color: #d54167 !important;
  }

  // Move the revoke button to the left screen border if the pointer device does not support hovering
  @media (hover: none) {
    left: 0 !important;
    top: 80px !important;
    padding: 5px 8px;
    border-radius: 10px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0 !important;
  }
}