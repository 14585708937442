body.article{
  #page {

    .neos-contentcollection{
        margin-bottom: 0px;
    }


      @media (max-width: $screen-sm-min) {
        .neos-contentcollection{
            margin-bottom: 0px;
        }

        .page-title {
          h1 {
            font-size: 1em;
            line-height: 25px;
          }
        }
      }

    .obisconcept-mainwebsite-section {
      padding: 5px 0 5px 0;
    }
    .date-wrapper{
        i{
            background-color: $darkgrey;
            width: 45px;
            height: 45px;
            color: white;
            font-size: 22px;
            display: table;
            border-radius: 100%;
            float:right;
            &::before{
                display: table-cell;
                vertical-align: middle;
                text-align: center;
            }
        }
        p.article-date{
            position: relative;
            margin-bottom: 5px;
            font-size: 16px;
            color: #333333;
            padding-top: 15px;
            padding-right: 5px;
            float: right;
            width: 100%;
            text-align: left;
            &::after{
                position: absolute;
                content: '';
                border-bottom: 2px solid $white;
                border-radius: 2px;
                width: 50px;
                bottom: -15px;
                right: 0px;
            }
        }
    }
  }
}


#back-link{
    display: inline-block;
    margin-top: 40px;
   color: $darkgrey;
   font-size: 14px;
   text-transform: uppercase;
   border: 1px solid $darkgrey;
    color: $darkgrey;
   padding: 10px;
   padding-left: 20px;
    padding-right: 15px !important;
   &:hover{
       color: $darkgrey;
       text-decoration: none;
   }
   i{
       margin-right: 17px;
       transform: scale(2);
       margin-top: -3px;
       margin-left: -10px;
   }
}
