@mixin clearfix {
  &::before, &::after {
    content: " ";
    display: table;
  }

  &::after {
    clear: both;
  }
}

@mixin custom-clearfix {
  display: block;
  clear: both;
  content: " ";
}

@mixin dynamic-background-preconfig {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

@mixin fake-column($size, $align: left) {
  // position: relative;
  width: 100% / 12 * $size;
  float: $align;
  min-height: 1px;
}

@mixin slide-height-mixin {
  min-height: 600px;
  max-height: 100vh;
  height: 70vh;
}

@mixin flexbox-container {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

@mixin flexbox-stretch-container {
  display: flex;
  align-content: stretch;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;
}