div.cardgallery {
    margin: 15px 0;
    .gallery-inner {
        @include flexbox-stretch-container;
        justify-content: center;
        @media (min-width: $screen-sm-min) {
            // justify-content: flex-start;
            justify-content: space-between;
        }
        .card-item {
            min-height: 200px;
            min-width: 200px;
            max-height: 350px;
            max-width: 500px;
            color: #fff;
            background-color: $orange;
            background-color: #{'rgb(var(--main-bg-color))'};
            width: 99%;
            margin-bottom: 1%;
            @media (min-width: $screen-sm-min) {
                width: 49%;
                max-width: unset;
            }
            @media (min-width: $screen-md-min) {
                width: 32%;
            }
            &:before {
                content: "";
                float: left;
                padding-top: 100%;
            }
            &.cardgallery-card {
                .icon-wrapper {
                    padding-top:50px;
                }
                .card-inner {
                    position: relative;
                    height: 100%;
                    width: 100%;
                    @include dynamic-background-preconfig;
                    .content-wrapper {
                        @include flexbox-container;
                        position: relative;
                        flex-direction: column;
                        height: 100%;
                        width: 100%;
                        padding: 5px;
                        &>.neos-contentcollection {
                            min-width: 100px;
                            h1 {
                                font-size: 3.5em;
                                margin: 5px 0;
                            }
                            h2 {
                                font-size: 3em;
                                margin: 5px 0;
                            }
                            h3 {
                                font-size: 2.5em;
                                margin: 5px 0;
                            }
                            h4 {
                                font-size: 2em;
                                margin: 5px 0;
                            }
                            h5 {
                                font-size: 1.5em;
                                margin: 5px 0;
                            }
                        }
                        transition: all 0.3s ease-in-out;
                        &:hover {
                            color: $lightgrey;
                            text-shadow: 0 0 3px $darkgrey;
                        }
                    }
                }
            }
            &.cardgallery-flipcard {
                max-height: 300px;
                .icon-wrapper {
                    display: inline-table;
                }
                .card-inner {
                    position: relative;
                    height: 100%;
                    width: 100%;
                    .frontend,
                    .backend {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        @include flexbox-container;
                        backface-visibility: hidden;
                        transition: transform 0.4s ease-in-out;
                        padding: 15px;
                    }
                    .frontend {
                        background-size: cover;
                        transform: rotateY(0deg);
                        display: inline-grid;
                        .headline {
                            display: block;
                            font-size: 2em;
                            font-weight: bold;
                            margin: 10px 0;
                            text-transform: uppercase;
                        }
                    }
                    .backend {
                        transform: rotateY(180deg);
                    }
                    &:hover {
                        .frontend {
                            transform: rotateY(180deg);
                        }
                        .backend {
                            transform: rotateY(0deg);
                        }
                    }
                }
            }

            &.align-top {
                .card-inner .content-wrapper {
                    justify-content: flex-start;
                }
            }
            &.align-middle {
                .card-inner .content-wrapper {
                    justify-content: center;
                }
            }
            &.align-bottom {
                .card-inner .content-wrapper {
                    justify-content: flex-end;
                }
            }
        }
    }
    .icon-wrapper {
        width: 100%;
        display: block;
        margin: 0 auto;
        .icon {
            max-height: 50px;
            path {
                fill: white;
                transition: all 0.2s ease-in-out;
            }
            path.neos-grey {
                fill: white;
            }
            path.neos-white {
                fill: $darkgrey;
                stroke: white;
            }
        }
    }
}