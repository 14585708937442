.neos-nodetypes-text {
    &.color-dark {
        color: $darkgrey;
    }
    &.color-light {
        color: $lightgrey;
    }
    &.color-orange {
        color: $orange;
    }
    &.color-white {
        color: #fff;
    }

/*
    &.size-small p {
        font-size: small;
    }
    &.size-medium p {
        font-size: medium;
    }
    &.size-large p {
        font-size: large;
    }
    &.size-xxlarge p {
        font-size: xx-large;
    }
    &.size-xxxlarge p {
        font-size: -webkit-xxx-large;
    }
    &.size-xxxultra p {
        font-size: xxx-large;
        font-weight: 800 !important;
    }
*/

    &.size-01-xlight p {
        font-size: x-small;
        font-family: 'TheSans-XLight', sans-serif;
    }

    &.size-02-light p {
        font-size: small;
        font-family: 'TheSans-Light', sans-serif;
    }

    &.size-03-semilight p {
        font-size: medium;
        font-family: 'TheSans-SemiLight', sans-serif;
    }

    &.size-04-regular p {
        font-size: medium;
        font-family: 'TheSans-Regular', sans-serif;
    }

    &.size-05-semibold p {
        font-size: large;
        font-family: 'TheSans-SemiBold', sans-serif;
    }

    &.size-06-bold p {
        font-size: x-large;
        font-family: 'TheSans-Bold', sans-serif;
    }

    &.size-07-xbold p {
        font-size: xx-large;
        font-family: 'TheSans-XBold', sans-serif;
    }

    &.size-08-black p {
        font-size: xxx-large;
        font-family: 'TheSans-Black', sans-serif;
    }

    h1 {
        overflow-wrap: break-word;
    }
}