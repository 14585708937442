a.more-news{
    float: right;
    clear: both;
    margin-right: 25px;
    border: 1px solid #777;
    padding: 6px;
    padding-left: 10px;
    padding-right: 10px;
    i{
        transform: translateY(1px);
    }
}
